<template>
  <div class="AdminSettings">
    <ul class="SettingsList">
      <!-- Base menu -->
      <template v-if="!showShare">
        <li class="SettingsListItem">
          <a
            class="SettingsListItem--Link"
            :href="modeSwitchLink"
            data-ux="Explore_OrganizationPublicPage_AdminSettings_SwitchMode"
          >
            <HaIcon
              :icon="editMode ? faRocket : faWrench"
              class="SettingsListItem--Icon"
              aria-hidden="true"
              fixed-width
            />
            <span class="SettingsListItem--Title-Mobile">
              {{
                editMode
                  ? $t('adminSettings.seePublicPageShort')
                  : $t('adminSettings.seeEditModeShort')
              }}
            </span>
            <span class="SettingsListItem--Title-Desktop">
              {{
                editMode
                  ? $t('adminSettings.seePublicPage')
                  : $t('adminSettings.seeEditMode')
              }}
            </span>
          </a>
        </li>
        <li class="SettingsListItem">
          <button
            class="SettingsListItem--Link"
            data-ux="Explore_OrganizationPublicPage_AdminSettings_Toggle_ShareSubmenu"
            @click="showShare = true"
          >
            <HaIcon
              :icon="faShareNodes"
              class="SettingsListItem--Icon"
              aria-hidden="true"
              fixed-width
            />
            <span class="SettingsListItem--Title-Mobile">
              {{ $t('adminSettings.shareShort') }}
            </span>
            <span class="SettingsListItem--Title-Desktop">
              {{ $t('adminSettings.share') }}
            </span>
          </button>
        </li>
        <li class="SettingsListItem">
          <a
            class="SettingsListItem--Link"
            :href="`${configEnv.NUXT_ENV_BO_URL}/${route.params.organization}/accueil`"
            target="_blank"
            data-ux="Explore_OrganizationPublicPage_AdminSettings_AdminOrganization"
            rel="noopener"
          >
            <HaIcon
              :icon="faWrench"
              class="SettingsListItem--Icon"
              aria-hidden="true"
              fixed-width
            />
            <span class="SettingsListItem--Title-Mobile">
              {{ $t('adminSettings.adminShort') }}
            </span>
            <span class="SettingsListItem--Title-Desktop">
              {{ $t('adminSettings.admin') }}
              <span class="visually-hidden">
                {{ $t('accessibility.openNewWindow') }}
              </span>
            </span>
          </a>
        </li>
      </template>
      <!-- Share submenu -->
      <template v-else>
        <!-- Back to main menu -->
        <li class="SettingsListItem SettingsListItem-Back">
          <button
            class="SettingsListItem--Link"
            data-ux="Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_BackToMainMenu"
            @click="showShare = false"
          >
            <HaIcon
              :icon="faArrowLeft"
              fixed-width
            />
            <span class="visually-hidden">
              {{ $t('adminSettings.submenu.backLabel') }}
            </span>
          </button>
        </li>
        <!-- WhatsApp / Facebook / Twitter / LinkedIn -->
        <li
          v-for="item in items"
          :key="item.name"
          class="SettingsListItem"
        >
          <button
            class="SettingsListItem--Link"
            :data-ux="item.dataUx"
            @click="openSharePopup(item.href)"
          >
            <HaIcon
              :icon="item.icon"
              :class="`SettingsListItem--Icon SettingsListItem--Icon-${capitalize(
                item.name
              )}`"
              aria-hidden="true"
              fixed-width
            />
            <span class="SettingsListItem--Title-Mobile">
              {{ item.labelShort }}
            </span>
            <span class="SettingsListItem--Title-Desktop">
              {{ item.label }}
            </span>
          </button>
        </li>
        <client-only>
          <template v-if="isMobile">
            <!-- Messenger -->
            <li class="SettingsListItem">
              <a
                :href="`fb-messenger://share/?link=${organizationUrl}&app_id=${appId}`"
                class="SettingsListItem--Link"
                data-ux="Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_ShareByMessenger"
              >
                <HaIcon
                  :icon="faFacebookMessenger"
                  class="SettingsListItem--Icon SettingsListItem--Icon-Messenger"
                  aria-hidden="true"
                  fixed-width
                />
                <span class="SettingsListItem--Title">
                  {{
                    $t(
                      'adminSettings.submenu.networks.messenger.labelShort'
                    )
                  }}
                </span>
              </a>
            </li>
          </template>
        </client-only>
        <!-- Email -->
        <li class="SettingsListItem">
          <a
            class="SettingsListItem--Link"
            :href="`mailto:?to=&subject=${$t(
              'adminSettings.submenu.networks.email.subject',
              [organization.name]
            )}&body=${$t(
              'adminSettings.submenu.networks.email.body',
              [organizationUrl]
            )}`"
            data-ux="Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_ShareByEmail"
          >
            <HaIcon
              :icon="faEnvelope"
              class="SettingsListItem--Icon SettingsListItem--Icon-Email"
              aria-hidden="true"
              fixed-width
            />
            <span class="SettingsListItem--Title-Mobile">
              {{
                $t('adminSettings.submenu.networks.email.labelShort')
              }}
            </span>
            <span class="SettingsListItem--Title-Desktop">
              {{ $t('adminSettings.submenu.networks.email.label') }}
            </span>
          </a>
        </li>
        <!-- Copy URL -->
        <li class="SettingsListItem">
          <button
            class="SettingsListItem--Link"
            data-ux="Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_CopyUrl"
            @click="copyUrl(organizationUrl)"
          >
            <HaIcon
              :icon="faLink"
              class="SettingsListItem--Icon SettingsListItem--Icon-CopyUrl"
              aria-hidden="true"
              fixed-width
            />
            <span class="SettingsListItem--Title-Mobile">
              {{
                $t(
                  'adminSettings.submenu.networks.copyUrl.labelShort'
                )
              }}
            </span>
            <span class="SettingsListItem--Title-Desktop">
              {{ $t('adminSettings.submenu.networks.copyUrl.label') }}
            </span>
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  useRoute,
  onBeforeMount,
  useI18n
} from '#imports'
import {
  faRocket,
  faWrench,
  faShareNodes,
  faArrowLeft,
  faEnvelope,
  faLink
} from '@fortawesome/pro-solid-svg-icons'
import {
  faFacebookMessenger,
  faWhatsapp,
  faFacebookF,
  faXTwitter,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import { HaIcon } from '#components'
import useNotifications from '@ha/components-v3/src/composables/useNotifications'
import { capitalizeFirstLetter } from '@/helpers'
import { isMobileDevice } from '@/helpers/detectDevices'
import { facebookAppId } from '@/constants'
import useConfigEnv from '@/composables/useConfigEnv'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '@/store/organization.store'

const { pushNotification } = useNotifications()
const configEnv = useConfigEnv()

const organizationStore = useOrganizationStore()
const i18n = useI18n()
const route = useRoute()

const { editMode } = useEditMode()

const showShare = ref(false)
const appId = ref(facebookAppId)
const isMobile = ref(false)

const modeSwitchLink = computed(() => {
  return editMode.value
    ? `/associations/${route.params.organization}`
    : `/associations/${route.params.organization}/administration/ma-page-publique`
})

const organization = computed(() => {
  return organizationStore.organization
})

const organizationUrl = computed(() => {
  const slug = organization.value.organizationSlug
  return `${configEnv.NUXT_ENV_BASE_URL}/associations/${slug}`
})

const items = computed(() => {
  return [
    {
      name: 'whatsapp',
      icon: faWhatsapp,
      label: i18n.t('adminSettings.submenu.networks.whatsapp.label'),
      labelShort: i18n.t(
        'adminSettings.submenu.networks.whatsapp.labelShort'
      ),
      href: `https://api.whatsapp.com/send?text=${i18n.t(
        'adminSettings.submenu.networks.whatsapp.text',
        [organization.value.name, organizationUrl.value]
      )}`,
      dataUx:
        'Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_ShareOnWhatApp'
    },
    {
      name: 'facebook',
      icon: faFacebookF,
      label: i18n.t('adminSettings.submenu.networks.facebook.label'),
      labelShort: i18n.t(
        'adminSettings.submenu.networks.facebook.labelShort'
      ),
      href: `https://www.facebook.com/sharer/sharer.php?u=${organizationUrl.value}`,
      dataUx:
        'Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_ShareOnFacebook'
    },
    {
      name: 'twitter',
      icon: faXTwitter,
      label: i18n.t('adminSettings.submenu.networks.twitter.label'),
      labelShort: i18n.t(
        'adminSettings.submenu.networks.twitter.labelShort'
      ),
      href: `https://twitter.com/intent/tweet?text=${i18n.t(
        'adminSettings.submenu.networks.twitter.text',
        [organization.value.name, organizationUrl.value]
      )}`,
      dataUx:
        'Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_ShareOnOnTwitter'
    },
    {
      name: 'linkedin',
      icon: faLinkedin,
      label: i18n.t('adminSettings.submenu.networks.linkedin.label'),
      labelShort: i18n.t(
        'adminSettings.submenu.networks.linkedin.labelShort'
      ),
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${organizationUrl.value}&title=${organization.value.name}&source=HelloAsso`,
      dataUx:
        'Explore_OrganizationPublicPage_AdminSettings_ShareSubmenu_ShareOnOnLinkedIn'
    }
  ]
})

onBeforeMount(async () => {
  isMobile.value = await isMobileDevice()
})

const copyUrl = (href) => {
  navigator.clipboard.writeText(href)
  pushNotification({
    type: 'success',
    title: i18n.t('toast.copyUrl.title'),
    body: i18n.t('toast.copyUrl.body'),
    timeout: 5000
  })
}

const openSharePopup = (href) => {
  const popupWidth = 560
  const popupHeight = 480
  const popupXPosition =
    window.screenLeft + (window.screen.availWidth - popupWidth) / 2
  const popupYPosition =
    window.screenTop + (window.screen.availHeight - popupHeight) / 2

  window.open(
    href,
    'Share',
    `width=${popupWidth},height=${popupHeight},left=${popupXPosition},top=${popupYPosition}`
  )
}

const capitalize = (value) => {
  return capitalizeFirstLetter(value)
}
</script>

<style lang="scss" scoped>
.AdminSettings {
  width: 100%;

  @include mediaQuery(600) {
    width: auto;
  }
}

.SettingsList {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  background-color: var(--ha-color-white);
  box-shadow: $ha-box-shadow-middle;

  @include mediaQuery(600) {
    display: block;
    padding: $ha-spacing-tiny 0;
    border-radius: $ha-radius-large;
  }

  &Item {
    width: 33.333%;
    list-style: none;

    @include mediaQuery(600) {
      width: auto;
    }

    &-Back {
      svg {
        height: 100%;

        @include mediaQuery(600) {
          height: $ha-unit * 2;
        }
      }
    }

    &--Link {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: $ha-spacing-medium $ha-spacing-medium $ha-spacing-small;
      color: var(--ha-color-text-light);
      font-size: $ha-unit * 1.25; // 11px
      text-align: center;
      transition: padding-left 0.15s ease;

      @include mediaQuery(600) {
        flex-direction: row;
        padding: $ha-spacing-small $ha-spacing-jumbo $ha-spacing-small
          $ha-spacing-large;
        font-weight: $ha-font-weight-semibold;
        font-size: $ha-font-size-small;
        text-align: left;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      &:hover,
      &:focus {
        color: var(--ha-color-text);
        font-weight: $ha-font-weight-semibold;
        text-decoration: none;
        background-color: var(--ha-color-background);

        .SettingsListItem--Icon {
          color: var(--ha-color-text-light);

          &-Whatsapp {
            color: var(--hads-color-whatsapp);
          }

          &-Facebook {
            color: var(--hads-color-facebook);
          }

          &-Twitter {
            color: var(--hads-color-twitter);
          }

          &-Linkedin {
            color: var(--hads-color-linkedin);
          }

          &-Messenger {
            color: var(--hads-color-messenger);
          }
        }
      }

      + .SettingsListItem--Link {
        border-left: $ha-border-regular;

        @include mediaQuery(600) {
          border: none;
        }
      }
    }

    &--Icon {
      margin-bottom: $ha-spacing-mini;
      color: var(--ha-color-text-lightest);
      font-size: $ha-font-size-regular;
      transition: inherit;

      @include mediaQuery(600) {
        margin-right: $ha-spacing-small;
        margin-bottom: 0;
        font-size: inherit;
      }

      &-Whatsapp {
        color: var(--hads-color-whatsapp);
      }

      &-Facebook {
        color: var(--hads-color-facebook);
      }

      &-Twitter {
        color: var(--hads-color-twitter);
      }

      &-Linkedin {
        color: var(--hads-color-linkedin);
      }

      &-Messenger {
        color: var(--hads-color-messenger);
      }
    }

    &--Title {
      &-Desktop {
        display: none;

        @include mediaQuery(600) {
          display: inline-block;
        }
      }

      &-Mobile {
        @include mediaQuery(600) {
          display: none;
        }
      }
    }
  }
}
</style>
